// use this file to customize material components styles
@use 'colors';

// form-field
.mat-form-field {
	font-family: 'Roboto', 'Tajawal', 'monospace' !important;

	.mat-form-field-label {
		font-family: 'Roboto', 'Tajawal', 'monospace' !important;
		color: inherit;
	}
}

// form field appearance = outline, coppied from original material stylesheet
.mat-form-field-appearance-outline {
	.mat-input-element {
		color: colors.$input-color !important;
	}

	.mat-form-field-outline {
		color: inherit;
		background: colors.$default-bg-color;
		border-radius: 8px;
	}

	.mat-form-field-outline-start,
	.mat-form-field-outline-gap,
	.mat-form-field-outline-end {
		border: 2px solid currentColor !important;
		opacity: 0;
	}

	.mat-form-field-outline-start {
		min-width: 8px !important;
	}
	.mat-form-field-outline-gap {
		border-radius: 8px !important;
		border-left-style: none !important;
		border-right-style: none !important;
	}

	.mat-form-field-outline-end {
		min-width: 8px !important;
	}

	.mat-form-field-outline-thick {
		color: colors.$primary-color;
		border-radius: 8px;
		.mat-form-field-outline-start,
		.mat-form-field-outline-gap,
		.mat-form-field-outline-end {
			opacity: 1;
		}
	}

	&.mat-focused {
		.mat-form-field-outline-thick {
			color: colors.$primary-color;
		}

		&.mat-accent .mat-form-field-outline-thick {
			color: colors.$warn-color;
		}

		&.mat-warn .mat-form-field-outline-thick {
			color: colors.$warn-color;
		}
	}

	// Class repeated so that rule is specific enough to override focused accent color case.
	&.mat-form-field-invalid.mat-form-field-invalid {
		.mat-input-element {
			color: colors.$warn-color !important;
		}

		.mat-form-field-outline-thick {
			color: colors.$warn-color;
		}
	}

	&.mat-form-field-disabled {
		.mat-input-element {
			color: white !important;
		}

		.mat-form-field-outline {
			background: colors.$disabled-bg-color;
		}
	}
}

html[dir='ltr'] .mat-form-field-appearance-outline {
	.mat-form-field-outline-start {
		border-radius: 8px 0 0 8px !important;
		border-right-style: none !important;
	}

	.mat-form-field-outline-end {
		border-radius: 0 8px 8px 0 !important;
		border-left-style: none !important;
	}
}
html[dir='rtl'] .mat-form-field-appearance-outline {
	.mat-form-field-outline-start {
		border-radius: 0 8px 8px 0 !important;
		border-left-style: none !important;
	}

	.mat-form-field-outline-end {
		border-radius: 8px 0 0 8px !important;
		border-right-style: none !important;
	}
}

.mat-focused {
	color: colors.$primary-color !important;
}

mat-label,
mat-radio-button,
label {
	font-family: 'Roboto', 'Tajawal', 'monospace' !important;
}

// radio button colors
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
	// opacity: 0.5 !important; /*click effect color change*/
	background-color: colors.$primary-color !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
	background-color: colors.$primary-color !important; /*inner circle color change*/
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
	border-color: colors.$primary-color !important; /*outer ring color change*/
}

// checkbox button colors
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
	// opacity: 0.5 !important; /*click effect color change*/
	background-color: colors.$primary-color !important;
}

.mat-checkbox-ripple .mat-ripple-element {
	// opacity: 0.5 !important; /*click effect color change*/
	background-color: colors.$primary-color !important;
}

html[dir='rtl'] .rt-rtl-mirror {
	transform: scale(-1, 1) !important;
}

// stepper
.mat-stepper-vertical,
.mat-stepper-horizontal,
.mat-panel-title,
.mat-expansion-panel-header,
.mat-expansion-panel-content {
	font-family: inherit;
}

.mat-step-icon-state-done {
	background-color: colors.$success-color !important;
}

.mat-step-icon-state-edit.mat-step-icon-selected,
.mat-step-icon-state-number.mat-step-icon-selected {
	background-color: colors.$primary-color;
}

.mat-stepper-horizontal,
.mat-stepper-vertical {
	@media screen and (max-width: 992px) {
		background-color: transparent !important;
	}
}

.mat-horizontal-stepper-header-container,
.mat-horizontal-stepper-header {
	overflow: auto;
}

// select
.mat-select-value,
.mat-select,
.mat-option {
	color: colors.$input-color !important;
	font-family: inherit;
}

.mat-select-arrow {
	opacity: 0 !important;
}

// expansion Panel
.mat-expansion-panel,
.private-expansion-focus,
.mat-expansion-panel:not(.mat-expanded):not([aria-disabled='true']) .mat-expansion-panel-header:hover {
	background-color: colors.$default-bg-color !important;
}

.mat-expansion-panel {
	box-shadow: none !important;
	margin-bottom: 1rem !important;
	border-radius: 8px !important;
}

.mat-expansion-panel .mat-form-field-outline {
	background-color: #ffffff !important;
}

.mat-expansion-panel-header-title,
.mat-expansion-panel-body {
	color: colors.$input-color !important;
}
