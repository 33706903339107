// color Palette

// material
$default-bg-color: #f1f4f5;
$disabled-bg-color: #dadada;
$primary-color: #2e87e2;
$warn-color: #f13023;
$success-color: #13b52d;
$input-color: #323232;
$label-color: #808a92;

// bootstrap card
$card-title-color: #52595f;

// social/admin cards
$blue-color: #0981f0;
