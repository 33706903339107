/* You can add global styles to this file, and also import other style files */
@use 'styles/scss/colors';
@import '@angular/material/theming';
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import 'styles/scss/customized-material.scss';

html {
	font-family: 'sans-serif';
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body {
	height: 100%;
	width: 100%;

	font-family: 'Poppins', 'Tajawal', 'Helvetica', 'sans-serif' !important;
	font-size: 13px !important;
	font-weight: 300 !important;
	color: rgb(69, 79, 99) !important;

	-ms-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	a:hover,
	a:active,
	a:focus {
		text-decoration: none !important;
	}

	a,
	button {
		outline: none !important;
	}
}

body {
	@media screen and (max-width: 991.98px) {
		background-color: #ffffff !important;
	}

	@media screen and (min-width: 992px) {
		background-color: rgb(247, 247, 247) !important;
	}
}

.page-loaded {
	opacity: 1;
	transition: opacity 1s ease-in-out;
}

.rt-button {
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	font-family: inherit;
	border-radius: 8px !important;
}

.rt-button-wrapper {
	height: 56px;
}

.rt-button-blue {
	background-color: colors.$primary-color;
}

.rt-icon-button-blue {
	color: colors.$primary-color;
}

.rt-icon-button-warn {
	color: colors.$warn-color;
}

.rt-button-blue-stroked {
	border: 1px solid colors.$primary-color !important;
	color: colors.$primary-color !important;
}

.rt-phone-number {
	direction: ltr;
}

.rt-totals-icon {
	font-size: 15.5px !important;
	color: #8e9195;
}

.rt-clickable {
	cursor: pointer;
}

// card
.card-title,
.card-subtitle {
	color: colors.$card-title-color;
}

.card-title {
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 22px;
}

.card-subtitle {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 19px;
}

.rt-label-text {
	font-weight: normal;
	font-size: 14px;
	line-height: 19px;
	color: colors.$label-color;

	.rt-required-marker {
		color: colors.$warn-color;
		font-weight: 600;
		font-size: 14px;
		line-height: 19px;
	}
}

// reCaptcha Styles
.grecaptcha-badge {
	visibility: hidden;
}
